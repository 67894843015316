import { useState, useEffect } from 'react';

const useSegmentDataCalculations = (segmentData) => {
    const [calculatedData, setData] = useState([]);
    const [totalMarketSize, setTotalMarketSize] = useState(0);

    useEffect(() => {
        if (!segmentData || segmentData.length === 0) return;

        // Calculate CLV for each segment
        const calculatedSegmentData = segmentData.map((segment) => ({
            segmentName: segment.segmentName,
            CLV: segment.APV * segment.PF * segment.ACL,
            customers: segment.Customers,
        }));

        // Calculate total value of each segment
        calculatedSegmentData.forEach((segment) => {
            segment.totalValue = segment.CLV * segment.customers;
        });

        // Calculate total value of all segments combined
        const totalMarketSize = calculatedSegmentData.reduce(
            (total, segment) => total + segment.totalValue,
            0
        );

        setTotalMarketSize(totalMarketSize);

        // Calculate percentages for each segment
        const finalData = calculatedSegmentData.map((segment) => {
            const percentage = (segment.totalValue / totalMarketSize) * 100;
            let value;
            if (percentage < 1) {
                value = 1;
            } else if(percentage === 100 && calculatedSegmentData.length===1){
                value = 100;
            }else if (percentage >= 100) {
                value = 99;
            } else {
                value = Math.floor(percentage);
            }
            return {
                type: segment.segmentName,
                value: value,
                segValue: segment.totalValue,
            };
        });

        setData(finalData);
    }, [segmentData]);

    return { calculatedData, totalMarketSize };
};

export default useSegmentDataCalculations;