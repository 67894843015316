import React, { useState, useEffect } from "react";
import { Pie, measureTextWidth } from "@ant-design/plots";
import { useSelector } from  "react-redux";
import tmaLocals from "../../localization/localization_en_target_market_analysis.json"
import { convertAmountInK } from "../../utils/convertAmountInK";

const TMAPieChart = ({ data, colorPalette, isShowTotalScore, totalMarketSize }) => {
  
  const colors = useSelector((state) => state.tma.activeColors);

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: colors.length===0 ? colorPalette : colors,
    radius: 1,
    innerRadius: 0.6,
    legend: {
      visible: false,
      custom: true,
      layout: 'vertical',
      position: 'bottom-left',
      items: [
        ...data.map((item,i) => {
          return {
            value: item.type,
            name: `${item.type} ${item.value}% ($${item.segValue}k)`,
            marker: { symbol: "square", style: { fill: colors.length>0 ? colors[i] : colorPalette[i] } }
          };
        }),
      ],
    },
    label: {
      type: 'outer',
      offset: '50%',
      content: '  {value}%',
      style: {
        textAlign: 'center',
        fontSize: 12,
      },
      autoRotate: true, 
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: isShowTotalScore ? convertAmountInK(totalMarketSize) : '',
      },
    },
  };
  return <Pie {...config} />;
};

export default TMAPieChart;
