import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import editIcon from "../assets/targetmarketanalysis/Edit Square.svg";
import deleteIcon from "../assets/targetmarketanalysis/Delete.svg";
import {
  updateShowSegmentInput,
  updateSegmentType,
  patchSegmentData,
  deleteSegment,
  postSegmentData,
  getSegmentData,
} from "../features/targetmarketanalysisSlice";
import "../index.css";
import { TMABarGraph } from "../components/targetmarketanalysis/TMABarGraph";
import TMAPieChart from "../components/targetmarketanalysis/TMAPieChart";
import DescribeFeature from "../components/reuseablecomponent/DescribeFeature"
import TMASegmentInputs from "../components/TMASegmentInputs";
import { tmaResetStatusWithErr } from "../features/targetmarketanalysisSlice";
import PageSpinner from "../components/reuseablecomponent/PageSpinner";
import MessageNotification from "../components/reuseablecomponent/MessageNotification";
import OopsPopUp from "../components/reuseablecomponent/PopUpVarients/OopsPopUp";
import { postUserActivityDataApi } from "../components/UserActivity/userActivitySlice";
import tmaLocals from "../localization/localization_en_target_market_analysis.json";
import notificationLocals from "../localization/localization.en_notification_message.json"
import { demoTMAData, demoTMAGraphColorPalette } from "../data";
import AIWelcome from "../components/reuseablecomponent/PopUpVarients/AIWelcome";
import AIButton from "../DesignSystem/Buttons/AIButton";
import TextExtraLarge from "../DesignSystem/TypographyDescriptions/TextExtraLarge";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RedirectModuleToCardView from "../components/reuseablecomponent/RedirectModuleToCardView";
import PrimaryButton from "../DesignSystem/Buttons/PrimaryButton";
import WarningLimitedResourceOopsPopup from "../components/reuseablecomponent/PopUpVarients/WarningLimitedResourceOopsPopup";
import { paywall_locals } from "../localization";
import useApp from "../hooks/useApp";
import useSegmentDataCalculations from "../components/targetmarketanalysis/hooks/useSegmentDataCalculations";
import { convertAmountInK } from "../utils/convertAmountInK";

const TargetMarketAnalysis = () => {
  const linkInfo = useSelector((state) => state.link);
  const status = useSelector((state) => state.tma.status);
  const error = useSelector((state) => state.tma.error);
  const tma = useSelector((state) => state.tma);
  const dispatch = useDispatch();
  const demo = tma.segmentData.length > 0 ? false : true;
  const showSegmentInput = useSelector((state) => state.tma.showSegmentInput)
  const permission = useSelector((state) => state.user.permission);
  const [showToolTip, setShowToolTip] = useState(false);
  const { upgradeRenewPaywallPopup }=  useApp();



  let data = [];
  useEffect(() => {
    if (status === "failed" || status === "succeeded") {
      const timer = setTimeout(() => {
        dispatch(tmaResetStatusWithErr());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [status]);
  useEffect(() => {
    dispatch(getSegmentData());
  }, []);
  if (tma.segmentData.length > 0) {
    data = tma.segmentData;
  } else {
    data = demoTMAData.slice(0, 1);
  }
  const columns = [
    {
      title: (
        <div className="text-start pl-[12px] text-[18px] font-medium font-InterRegular py-[7.16px] text-primary">
          <p style={{ margin: "0px" }}>{tmaLocals.target_market_analysis.table.table_header.column1}</p>
        </div>
      ),
      dataIndex: "segmentName",
      render: (text, record, index) => {
        return (
          <div
            className={`${demo && "bg-black/10 blur-[0.5px]"} h-[104px] flex flex-col justify-end`}
          >
            <div className="flex items-center">
              <div className="">
                {/** <div className="w-[11px] outer-circle h-[11px] rounded-full bg-green-500 mr-[6.7px] ml-[10.5px]"></div> **/}
                <div
                  className="w-[15px] h-[15px] rounded-full mr-[6.7px] ml-[10.5px]"
                  style={{
                    backgroundColor:
                      tma.activeColors.length == 0
                        ? "#4ADE80"
                        : tma.activeColors[index],
                  }}
                ></div>
              </div>
              <div className=" font-InterRegular text-[16px] font-medium">
                <span className={`${demo ? 'text-[#9E9E9E]' : 'text-[#111827]'}`}>{text}</span>
              </div>
            </div>
            <div className="flex justify-end mb-[8px] mt-[24px] mr-[8px]">
              <button
                disabled={demo || !permission.edit}
                onClick={() => {
                  const filteredSegment = data.map((item, i) => {
                    if (i === index) {
                      dispatch(patchSegmentData({ updateSegment: false, item, key: index }));
                      dispatch(updateSegmentType({ upte: true }));
                      dispatch(updateShowSegmentInput());
                    }
                  });
                }}
              >
                <ModeEditOutlinedIcon fontSize="medium" className="text-primary" />
              </button>
              <button
                disabled={demo || !permission.delete}
                onClick={() => {
                  const slicedSegment = tma.segmentData.filter((item, i) => {
                    if (i !== index) {
                      return item;
                    }
                  });
                  dispatch(
                    deleteSegment({ slicedData: slicedSegment, index: index })
                  );
                  const deleteNotification = notificationLocals.notification_message.target_market_analysis.delete
                  dispatch(postSegmentData());
                  dispatch(postUserActivityDataApi({ ...deleteNotification }));
                }}
              >
                <DeleteOutlinedIcon fontSize="medium" className="text-infoAlertError1 ml-[20px]" />
              </button>
            </div>
          </div>
        );
      },
      width: "20%",
    },
    {
      title: (
        <div className="text-start pl-[12px] text-[18px] font-medium font-InterRegular py-[7.16px] text-primary">
          <p style={{ margin: "0px" }}>{tmaLocals.target_market_analysis.table.table_header.column2}</p>
        </div>
      ),
      dataIndex: "APV",
      width: "20%",
      render: (text) => {
        return (
          <div className={`${demo && "bg-black/10 blur-[0.5px]"} relative h-[104px]`}>
            <p className={`${demo ? 'text-[#9E9E9E]' : 'text-[#111827]'} text-center absolute top-[40%] left-1/2 -translate-x-1/2 text-[#111827] font-InterRegular text-[16px] font-medium`}>
              {tmaLocals.target_market_analysis.table.unit_names.unit} {text}
            </p>
          </div>
        );
      },
    },
    {
      title: (
        <div className="text-start pl-[12px] text-[18px] font-medium font-InterRegular py-[7.16px] text-primary">
          <p style={{ margin: "0px" }}>{tmaLocals.target_market_analysis.table.table_header.column3}</p>
        </div>
      ),
      dataIndex: "PF",
      render: (text) => {
        return (
          <div className={`${demo && "bg-black/10 blur-[0.5px]"} relative h-[104px]`}>
            <p className={`${demo ? 'text-[#9E9E9E]' : 'text-[#111827]'} text-center absolute top-[40%] left-1/2 -translate-x-1/2 font-InterRegular text-[16px] font-medium`}>
              {text}/{tmaLocals.target_market_analysis.table.unit_names.year}
            </p>
          </div>
        );
      },
    },
    {
      title: (
        <div className="text-start pl-[12px] text-[18px] font-medium font-InterRegular py-[7.16px] text-primary">
          <p style={{ margin: "0px" }}>{tmaLocals.target_market_analysis.table.table_header.column4}</p>
        </div>
      ),
      dataIndex: "ACL",
      render: (text) => {
        return (
          <div className={`${demo && "bg-black/10 blur-[0.5px]"} relative h-[104px]`}>
            <p className={`${demo ? 'text-[#9E9E9E]' : 'text-[#111827]'} text-center absolute top-[40%] left-1/2 -translate-x-1/2 font-InterRegular text-[16px] font-medium`}>
              {text}/{tmaLocals.target_market_analysis.table.unit_names.year}
            </p>
          </div>
        );
      },
    },
    {
      title: (
        <div className="text-start pl-[12px] text-[18px] font-medium font-InterRegular py-[7.16px] text-primary">
          <p style={{ margin: "0px" }}>{tmaLocals.target_market_analysis.table.table_header.column5}</p>
        </div>
      ),
      dataIndex: "Customers",
      render: (text) => {
        return (
          <div className={`${demo && "bg-black/10 blur-[0.5px]"} relative h-[104px]`}>
            <p className={`text-center ${demo ? 'text-[#9E9E9E]' : 'text-[#111827]'} absolute top-[40%] left-1/2 -translate-x-1/2 font-InterRegular text-[16px] font-medium`}>
              {text}/{tmaLocals.target_market_analysis.table.unit_names.year}
            </p>
          </div>
        );
      },
    },
  ];

  const graphData = demo ? demoTMAData : data;

  const { calculatedData, totalMarketSize } = useSegmentDataCalculations(graphData)
  return (
    <div className={`${linkInfo.collapsed ? "" : ""} w-[calc(100%-26px)] mt-[80px] mx-auto`}>
      <TMASegmentInputs isOpen={showSegmentInput} />
      <AIWelcome featureName={"tma"} link={"/product/home/ai/promptin/target-market-analysis"} />
      <AIButton />
      {status == "pending" && <PageSpinner />}
      {showToolTip && <OopsPopUp description1={tmaLocals.target_market_analysis.warning_err_message.limit_add_data} isOpen={showToolTip} onClick={() => setShowToolTip((prev) => !prev)} />}
      {(status == "failed" && tma.segmentData.length > 0) && <MessageNotification status={status} type={"error"} content={error} />}
      <DescribeFeature text={tmaLocals.target_market_analysis.module_title} description={tmaLocals.target_market_analysis.module_description} />
      <div className="mt-[40px]">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            position: ["none", "none"],
          }}
          scroll={{
            y: "40vh",
          }}
          bordered
        />
      </div>
      <div className="mt-[30px] flex justify-between">
        <RedirectModuleToCardView moduleName={'targetMarketAnakysis'} />
        <WarningLimitedResourceOopsPopup
          description1={paywall_locals.target_market_analysis[upgradeRenewPaywallPopup].add_segment.description1}
          description2={paywall_locals.target_market_analysis[upgradeRenewPaywallPopup].add_segment.description2}
        >
          {
            ({ handleTogglePopup, isFreeSubscription }) => {
              return (
                <PrimaryButton
                  isDisabled={!permission.edit}
                  onClick={() => {
                    if (tma.segmentData.length === 1 && isFreeSubscription) {
                      handleTogglePopup();
                      return;
                    }
                    if (tma.segmentData.length === 10) {
                      setShowToolTip((prev) => !prev);
                    } else {
                      dispatch(updateSegmentType({ new: true }));
                      dispatch(updateShowSegmentInput());
                    }
                  }}
                  text={tmaLocals.target_market_analysis.table.add_data_btn_text}
                />
              )
            }
          }
        </WarningLimitedResourceOopsPopup>
        <div></div>
      </div>
      <div className="graphs flex items-end mt-[142px]">
        <div className="piechart w-[50%] relative mb-[64px]">
          <div className="flex justify-center items-center mb-[1rem]">
            <TextExtraLarge fontWeight={600} text={
                String(totalMarketSize).length < 8
                  ? tmaLocals.target_market_analysis.graph.pie_chart.label
                  : tmaLocals.target_market_analysis.graph.pie_chart.label + ' : ' + convertAmountInK(totalMarketSize)
              }
            />
          </div>
          <TMAPieChart data={calculatedData} isShowTotalScore={String(totalMarketSize).length < 8} totalMarketSize={convertAmountInK(totalMarketSize)} colorPalette={demoTMAGraphColorPalette} />
        </div>
        <div className="BarChart w-[50%] relative mb-[64px]">
          <div className="flex justify-center items-center mb-[1rem]">
            <TextExtraLarge fontWeight={600} text={tmaLocals.target_market_analysis.graph.bar_graph.label} />
          </div>
          <TMABarGraph segmentData={graphData} colorPalette={demoTMAGraphColorPalette} />
        </div>
      </div>
    </div>
  );
};

export default TargetMarketAnalysis;
